import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CPStore } from 'apps/CPApp';
import MainFooter from 'components/Core/Footer/MainFooterComponent/MainFooter';
import Payment from 'components/Core/Payment/Payment';
import SidePanelWrapper from 'components/Core/SidePanel/Wrapper/SidePanelWrapper';
import PaymentRequestMoreDetails from 'components/PaymentReq/MoreDetails/PaymentRequestMoreDetails';
import ContactDetails from 'components/PaymentReq/OneToMany/ContactDetails/ContactDetails';
import FormSectionHeader from 'components/PaymentReq/OneToMany/FormSectionHeader/FormSectionHeader';
import OneToManyMerchantInfoCard from 'components/PaymentReq/OneToMany/MerchantInfoCard/OneToManyMerchantInfoCard';
import OneToManyMoreDetails from 'components/PaymentReq/OneToMany/MoreDetails/OneToManyMoreDetails';
import PaymentRequestPreviewCard from 'components/PaymentReq/PreviewCard/PaymentRequestPreviewCard';
import Card from 'components/Shared/Card/Card';
import { FeedbackLink } from 'components/Shared/Feedback/Feedback';
import PaymentAmountInput from 'components/Shared/Inputs/PaymentAmountInput';
import MobileInvoiceHeader from 'components/Shared/MobileInvoiceHeader/MobileInvoiceHeader';
import Separator from 'components/Shared/Separator/Separator';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import WavefrontReporter from 'reporting/wavefront/WavefrontReporter';
import { getPaymentErrorMessage } from 'shared/pagesHelpers';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { modalActions } from 'store/modal/slice';
import { isPaymentError } from 'store/payment/helpers';
import { getUsedPaymentMethod, getUsedPaymentType } from 'store/payment/selectors';
import { paymentAmountChange } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { walletSelectors } from 'store/wallet/selectors';
import { breakpoints, colors, fontSize } from 'styles/cp';
const splunkReporter = SplunkReporter.getInstance();
const wavefrontReporter = WavefrontReporter.getInstance();
let SuccessScreen;

const logger = 'pages/paymentReq';

class PaymentReq extends Component {
  constructor(props) {
    super(props);
  }

  static async getInitialProps() {
    return {
      pageProps: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { payment, wallet } = this.props;

    if (
      isPaymentError({
        prevPayment: prevProps.payment,
        currentPayment: payment,
      })
    ) {
      wavefrontReporter.trackPayment({
        paymentMethod: getUsedPaymentMethod({ payment: payment }),
        status: 'failed',
        subFlow: getUsedPaymentType({ payment: payment }),
        isPaymentUsingWallet: walletSelectors.isPaymentUsingWallet({ wallet, payment }),
      });
    }
  }

  componentDidMount() {
    import('components/Core/SuccessScreen/SuccessScreen/SuccessScreen').then(
      ({ default: SuccessScreenModule }) => {
        SuccessScreen = SuccessScreenModule;
      }
    );
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        screen: 'paymentReq',
      },
    });
  }

  renderErrorIfAny() {
    const {
      error,
      payment: { paymentStatus, paymentError },
      companyName,
      featureFlags,
    } = this.props;
    return getPaymentErrorMessage({
      error,
      paymentError,
      paymentStatus,
      companyName,
      featureFlags,
    });
  }

  render() {
    const {
      showModal,
      payment: { lastSuccessfulPaymentResponse, paymentMethodType },
      companyEmail,
      companyPhone,
      companyWebAddr,
      companyAddress,
      number,
      amount,
      balanceAmount,
      currency,
      description,
      txnDate,
      companyLocale,
      companyName,
      isFullyPaid,
      isPayable,
      companyLogoUrl,
      config,
      isOneToMany,
      estimatedDelivery,
    } = this.props;
    const country = companyLocale.split('_')[1];

    const fullPaymentMsg = (
      <span className="payment-full">
        <FormattedMessage id="PAYNOW_PAYMENT_FULL" defaultMessage="Paid in full" />
      </span>
    );

    // LastSuccessfulPaymentResponse should be an object that contain the necessary information for the success screen
    // LastSuccessfulPaymentResponse would be set to 'back_from_success' if we trigger hide success screen action
    if (
      lastSuccessfulPaymentResponse &&
      lastSuccessfulPaymentResponse !== 'back_from_success' &&
      SuccessScreen
    ) {
      return <SuccessScreen />;
    }
    return (
      <Fragment>
        <div className="main-wrapper">
          <SidePanelWrapper
            companyDetails={{ companyAddress, companyEmail, companyPhone, companyWebAddr, number }}
            config={config}
          >
            <PaymentRequestPreviewCard />
          </SidePanelWrapper>
          <div
            className={`main flex-column ${
              lastSuccessfulPaymentResponse === 'back_from_success' ? 'fade-in' : ''
            }`}
          >
            {this.renderErrorIfAny()}

            <Card>
              <MobileInvoiceHeader companyLogoUrl={companyLogoUrl} companyName={companyName} />
              {isOneToMany ? (
                <OneToManyMoreDetails
                  description={description}
                  estimatedDelivery={estimatedDelivery}
                  shouldBeInitiallyOpen={true}
                />
              ) : (
                <PaymentRequestMoreDetails
                  showModal={showModal}
                  companyEmail={companyEmail}
                  companyPhone={companyPhone}
                  companyWebAddr={companyWebAddr}
                  companyAddress={companyAddress}
                  number={number}
                  description={description}
                  txnDate={txnDate}
                  amount={amount}
                  balanceAmount={balanceAmount}
                  currency={currency}
                  totalAmount={this.props.payment.amount}
                />
              )}

              <div className="main-card-wrapper bottom">
                <div className="payment-amount-wrapper">
                  {isFullyPaid && fullPaymentMsg}
                  <span className="payment-amount-label">
                    <FormattedMessage
                      id="INVOICE_SUMMARY_MOBILE_PAYMENT_AMOUNT"
                      defaultMessage="payment amount"
                    />
                  </span>

                  <PaymentAmountInput
                    paymentAmountChange={this.props.paymentAmountChange}
                    intl={this.props.intl}
                    currency={currency}
                    balanceAmount={balanceAmount}
                    openInvoices={[]}
                    payment={this.props.payment}
                    featureFlags={this.props.featureFlags}
                  />
                </div>

                <Separator height={25} />

                {isOneToMany && (
                  <Fragment>
                    <ContactDetails />
                    <Separator height={40} />
                    <FormSectionHeader content="PAYMENT DETAILS" />
                    <Separator height={24} />
                  </Fragment>
                )}
                <Payment />
              </div>
              <MainFooter companyLocale={companyLocale} isPayable={isPayable} />
              <Separator height={20} />
            </Card>
            {isOneToMany && (
              <div className="paylink-merchant-info-card">
                <OneToManyMerchantInfoCard
                  companyAddress={companyAddress}
                  companyEmail={companyEmail}
                  companyPhone={companyPhone}
                  companyWebAddr={companyWebAddr}
                  number={companyName}
                  companyName={companyName}
                  showModal={showModal}
                />
              </div>
            )}
            {country && country.toLowerCase() === 'us' && (
              <span className="feedback-w">
                <FeedbackLink transactionType="PAYMENT_REQUEST" />
              </span>
            )}
          </div>
        </div>

        <style jsx>{`
          .main {
            width: 65%;
            padding: 15px 0;
            word-break: break-word;
            @media screen and (max-width: ${breakpoints.md}) {
              padding: ${companyLogoUrl ? '43px' : '10px'} 16px 0;
              margin-bottom: ${paymentMethodType ? '160px' : '0'};
              max-width: 100%;
              width: 100%;
            }

            .main-card-wrapper {
              padding: 15px 66px 0;
              max-width: 882px;

              @media screen and (max-width: ${breakpoints.md}) {
                padding: 20px 20px 0;
                &.bottom {
                  padding: 0 20px;
                }
              }

              .company-name {
                display: none;

                @media screen and (max-width: ${breakpoints.md}) {
                  display: block;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  color: ${colors.darkGray};
                  text-align: center;
                  font-family: AvenirNextforINTUIT-Bold;
                  font-size: 20px;
                  margin-top: 15px;
                }
              }

              .payment-amount-wrapper {
                text-align: left;
                margin-top: 10px;

                @media screen and (max-width: ${breakpoints.md}) {
                }
              }

              :global(.payment-full) {
                font-size: ${fontSize.xl};
                height: 35px;
                line-height: 0.53;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                color: ${colors.green};
                margin-top: 67px;
                display: block;
              }

              .payment-amount-label {
                font-family: AvenirNextforINTUIT-Demi;
                font-size: ${fontSize.xxs};
                color: ${colors.whiteGray};
                text-transform: uppercase;
                display: block;
              }

              .payment-amount {
                font-family: AvenirNextforINTUIT-Demi;
                font-size: ${fontSize.xl};
                line-height: 37px;
                color: ${colors.darkGray};
                text-transform: uppercase;
                display: block;
              }

              :global(.payment-confirmation-email-msg) {
                font-size: ${fontSize.sm};
                font-style: normal;
                font-stretch: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: center;
                display: block;
                color: ${colors.darkGray};
                margin-top: 50px;
              }

              :global(.contact-message) {
                font-size: ${fontSize.md};
                color: ${colors.darkGray};
                display: block;
                margin-top: 15px;
                margin-bottom: 25px;
                text-align: center;
                line-height: 1.3;

                @media screen and (max-width: ${breakpoints.md}) {
                  margin-bottom: 38px;
                }
              }
            }

            .paylink-merchant-info-card {
              display: none;

              @media screen and (max-width: ${breakpoints.md}) {
                display: block;
              }
            }

            .feedback-w {
              @media not screen and (max-width: ${breakpoints.md}) {
                display: none;
              }
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

function mapStateToProps({
  companyInfo,
  sale,
  insight,
  config,
  payment,
  featureFlags,
  auth,
  ixp,
  wallet,
}) {
  return {
    companyEmail: companyInfoSelectors.emailSelector(companyInfo),
    companyPhone: companyInfoSelectors.phoneSelector(companyInfo),
    companyWebAddr: companyInfoSelectors.webAddrSelector(companyInfo),
    companyAddress: companyInfoSelectors.addressSelector(companyInfo),
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    companyLogoUrl: companyInfoSelectors.logoSelector(companyInfo),
    number: saleSelectors.referenceNumberSelector(sale),
    amount: saleSelectors.amountSelector(sale),
    balanceAmount: saleSelectors.balanceSelector(sale),
    description: saleSelectors.descriptionSelector(sale),
    txnDate: saleSelectors.txnDateSelector(sale),
    dueDate: saleSelectors.dueDateSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    isPartiallyPaid: insightSelectors.isPartiallyPaidSelector(insight),
    token: insightSelectors.tokenSelector(insight),
    isPayable: insightSelectors.isPayableSelector(insight),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    isOneToMany: saleSelectors.isOneToManyPaymentRequest(sale),
    estimatedDelivery: saleSelectors.estimatedDeliverySelector(sale),

    config,
    payment,
    featureFlags,
    auth,
    ixp,
    wallet,
  };
}

const with_injectIntl = injectIntl(PaymentReq);
const with_connectToRedux = connect(mapStateToProps, {
  showModal: modalActions.show,
  paymentAmountChange,
})(with_injectIntl);
const with_CPStore = CPStore(with_connectToRedux);

export default with_CPStore;
